<template>
  <base-card dark>
    <section id="banner">
      <v-row no-gutters>
        <v-img
          :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
          :src="require(`@/assets/images/banner.JPG`)"
        >
          <v-theme-provider dark>
            <v-container class="fill-height container">
              <v-row
                align="center"
                class="white--text mx-auto"
                justify="center"
              >
                <v-col
                  class="white--text text-center"
                  cols="12"
                  tag="h1"
                >
                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4'
                    ]"
                    class="font-weight-black name"
                  >
                    Mahsa Aghamiri
                  </span>

                  <br>

                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'
                    ]"
                    class="font-weight-light bannerTitle"
                  >
                    Real Estate
                  </span>
                </v-col>

                <v-btn
                  class="align-self-end bannerTitle"
                  fab
                  outlined
                  @click="$vuetify.goTo('#about-me')"
                >
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>
  </base-card>
</template>

<script>
  export default {
    name: 'HomeBanner',
  }
</script>
<style scoped>
  .container {
    margin-top: -150px;
  }

  .name {
    background-color: #a2834c;
    position: relative;
  }

  .bannerTitle {
    background-color: #a2834c;
  }
</style>
